import * as React from 'react';

import classnames from 'classnames';

import { default as backgroundResizedMobile } from '@assets/kent-rebman-Hau9TnHS130-mobile.jpg?resize&sizes[]=400&sizes[]=600&sizes[]=800&sizes[]=1000'; // tslint:disable-line
import { default as backgroundResized } from '@assets/kent-rebman-Hau9TnHS130.jpg?resize&sizes[]=400&sizes[]=600&sizes[]=800&sizes[]=1000&sizes[]=1200&sizes[]=1600&sizes[]=2000'; // tslint:disable-line

import {
  createStyles,
  Fade,
  IconButton,
  useMediaQuery,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';

import { animateScroll } from 'react-scroll';

const styles = () =>
  createStyles({
    hero: {
      width: '100%',
      top: 0,
      left: 0,
      zIndex: -1,
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: '100%',
      objectFit: 'cover',
      transition: 'opacity 500ms ease-in'
    },
    heroOverlay: {
      width: '100%',
      top: 0,
      left: 0,
      zIndex: -1,
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: '100%',
      objectFit: 'cover',
      transition: 'opacity 500ms ease-in',
      backgroundColor: '#000',
      opacity: 0.2
    },
    heroOverlayDarker: {
      opacity: 0.4
    },
    buttonWrapper: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      bottom: 30
    },
    button: {
      color: 'white'
    }
  });

type IProps = WithStyles<typeof styles> & {
  isMobile?: boolean;
  isExpandable?: boolean;
};

const Hero: React.FC<IProps> = ({ classes, isMobile, isExpandable }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const onExpand = () => {
    animateScroll.scrollTo((window && window.innerHeight) || 1000);
  };

  return (
    <>
      {isExpandable && (
        <div className={classes.buttonWrapper}>
          <IconButton
            onClick={onExpand}
            aria-label="See More"
            className={classes.button}
          >
            <ExpandMoreRounded fontSize="inherit" />
          </IconButton>
        </div>
      )}
      <Fade in timeout={1000}>
        <img
          alt="Hero"
          className={classes.hero}
          srcSet={
            isMobile ? backgroundResizedMobile.srcSet : backgroundResized.srcSet
          }
          src={isMobile ? backgroundResizedMobile.src : backgroundResized.src}
        />
      </Fade>

      <div
        className={classnames(
          classes.heroOverlay,
          prefersDarkMode && classes.heroOverlayDarker
        )}
      />
    </>
  );
};

export default withStyles(styles)(Hero);
